import React from 'react';
import { connect } from 'react-redux'
import { getClient } from '../actions/client.actions';
import Client from '../components/Client.component';

const ClientContainer = (props) => {
    return (
        <div>
            <Client
                getClient={props.getClient}
                client={props.client}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    client: state.clients.client
});

const mapDispatchToProps = (dispatch) => {
    return {
        getClient: (id) => dispatch(getClient(id))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientContainer);