import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const OrderProducts = ({ products, status }) => {
    const { t } = useTranslation();

    const renderProducts = () => {
        return products.map((p, i) => {
            return (
                <tr key={i}>
                    <td>
                        <div className="order__name">
                            <p>{i + 1}. {_.get(p, 'name.pl')} </p>
                        </div>
                    </td>
                    <td>
                        <div className="order__netto d-flex align-items-center justify-content-center">
                            <p> {_.get(p, 'price')} {_.get(p, 'currency')} netto</p>
                        </div>
                    </td>
                    <td>
                        <div className="order__brutto d-flex align-items-center justify-content-center">
                            <p> {_.get(p, 'priceBrutto')} {_.get(p, 'currency')} brutto</p>
                        </div>
                    </td>
                    <td>
                        <div className="order__quantity">
                            <p className="text-center">{_.get(p, 'amount')} </p>
                        </div>
                    </td>

                    {
                        (status === "new") &&
                        <td>
                            <div className="order__stock">
                                <p className="text-center">{_.get(p, 'warehouseAmount')}</p>
                            </div>
                        </td>
                    }

                </tr>
            )
        })
    }
    return (
        <table className="table table-striped order__table">
            <thead>
                <tr>
                    <th scope="col">{t('name')}</th>
                    <th scope="col">{t('price')} netto</th>
                    <th scope="col">{t('price')} brutto</th>
                    <th scope="col" className="text-center">{t('total')}</th>
                    {
                        (status === "new") &&
                        <th scope="col" className="text-center">{t('stocks')}</th>
                    }
                </tr>
            </thead>

            <tbody>
                {renderProducts()}
            </tbody>
        </table>
    )
}

export default OrderProducts;