import React from 'react';
import NoImage from '../../../assets/img/noimage.svg'

const ProductImage = (props) => {
    const { image } = props;
    return (
        <div className="product__img">
            <img src={(image) ? image : NoImage} alt="" />
        </div>
    )
}

export default ProductImage;