import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux'
import _ from 'lodash';

import './stock.css'

import Stocks from '../../components/stock/Stock.component';

import { updateStocksSettings } from '../../actions/stock/stock.actions';


const StockContainer = ({ settings, updateStocksSettings }) => {
    const minMin = 1;
    const [minMax, setMinMax] = useState(20);
    const [midMin, setMidMin] = useState(21);
    const [midMax, setMidMax] = useState(50);
    const [maxMin, setMaxMin] = useState(51);
    const maxMax = '∞';

    useEffect(() => {
        setMinMax(_.get(settings, 'values[1].to'))
        setMidMin(_.get(settings, 'values[2].from'))
        setMidMax(_.get(settings, 'values[2].to'))
        setMaxMin(_.get(settings, 'values[3].from'))
    }, [settings])

    const handleSaveStocksSettings = () => {
        if (settings) {
            let newSettings = { ...settings };
            newSettings.values[1].to = parseInt(minMax);
            newSettings.values[2].from = parseInt(midMin);
            newSettings.values[2].to = parseInt(midMax);
            newSettings.values[3].from = parseInt(maxMin);

            updateStocksSettings(_.get(settings, '_id'), newSettings.values)
        }
    }

    return (
        <div className="col">
            <div className="row">
                <div className="col-12">
                    <div className="rounded-box p-5">
                        <Stocks
                            minMin={minMin}
                            minMax={minMax}
                            setMinMax={setMinMax}
                            midMin={midMin}
                            setMidMin={setMidMin}
                            midMax={midMax}
                            setMidMax={setMidMax}
                            maxMin={maxMin}
                            setMaxMin={setMaxMin}
                            maxMax={maxMax}
                            onClick={handleSaveStocksSettings}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    settings: _.find(_.get(state, 'app.settings'), (o) => {
        return o.type === 2
    })
});

const mapDispatchToProps = (dispatch) => {
    return {
        updateStocksSettings: (id, values) => dispatch(updateStocksSettings(id, values))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(StockContainer);