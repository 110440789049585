import {
    SET_CART,
    CLEAR_CART,
    SET_CART_ADDRESS,
    SET_CART_DESCRIPTION
} from '../actions/cart.actions';

const defaultState = {
    cart: [],
    address: null,
    // address: {
    //     country: "PL",
    //     _id: "5f9a9da1bd67164594a8aae6",
    //     isMainAddress: true,
    //     postCode: "11-345",
    //     locality: "Smoląg",
    //     street: "Franciszkanska",
    //     streetNumber: "123",
    //     area_1: "pomorskie",
    //     area_2: "starogardzki",
    //     area_3: "Bobowo"
    // },
    description: null
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case SET_CART:
            return {
                ...state,
                cart: action.cart
            }
        case CLEAR_CART:
            return {
                ...state,
                cart: []
            }
        case SET_CART_ADDRESS:
            return {
                ...state,
                address: action.address
            }
        case SET_CART_DESCRIPTION:
            return {
                ...state,
                description: action.description
            }
        default:
            return state;

    }
}