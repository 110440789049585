import React from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const OrderInfo = ({ orderId, customer, status }) => {
    const { t } = useTranslation();

    const renderStatus = () => {
        switch (status) {
            case 'new':
                return t('newOrder')
            case 'completed':
                return t('completed')
            case 'rejected':
                return t('rejected')
            default:
                return '-'
        }
    }
    return (
        <React.Fragment>
            <div className="row mt-5">
                <div className="col-12">
                    <h1>{t('order')} {orderId}</h1>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-12">
                    <div className="order-seller__client">
                        <span className="h4">{t('client')}:</span>
                        <Link to="#!"> {customer}</Link>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12">
                    <span className="h4">Status: </span>
                    <span className="order-seller__status">{renderStatus()}</span>
                </div>
            </div>
        </React.Fragment>
    )
}

export default OrderInfo;