import React from 'react';

const Switch = (props) => {
    const { id, label, onChange, value } = props;
    return (
        <div className="col-12 mt-5">
            <label>{label}</label>
            <label className="pure-material-switch">
                <input
                    name="admin-clients__active"
                    type="checkbox"
                    onChange={e => onChange(id, e.target.checked)}
                    checked={value}
                />
                <span></span>
            </label>
        </div>

    )
}

export default Switch;