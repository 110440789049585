import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import './orderProducts.css';

import ProductImage from '../../application/components/product/ProductImage.component'
import ProductPrice from '../../application/components/product/ProductPrice.component'

const OrderProducts = ({ products, showPrices }) => {
    const { t } = useTranslation();

    if (!products) return null;

    return products.map((p, i) => {
        return (
            <div className="order__item mt-5 pb-5 pb-md-0" key={i}>
                <div className="d-flex row align-items-center justify-content-between">

                    <div className="col-auto">
                        {i + 1}.
                    </div>
                    <div className="col" >
                        <div className="d-flex row align-items-center justify-content-end justify-content-md-start">
                            <div className="col-md-7 col-xl-auto d-flex align-items-center">
                                {/* <ProductImage
                                    image={_.get(p, 'images[0]')}
                                /> */}
                                <div className="ml-4">
                                    <h3>{_.get(p, 'name.pl')}</h3>
                                    <ProductPrice
                                        discountedPrice={_.get(p, 'discountedPrice')}
                                        priceBeforeDiscount={_.get(p, 'priceBeforeDiscount')}
                                        price={_.get(p, 'price')}
                                        currency={_.get(p, 'currency')}
                                        showPrices={showPrices}
                                    />
                                </div>
                            </div>
                            <div className="col-auto offset-1">
                                {_.get(p, 'amount')} {t('pcs')}
                </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    })
}

export default OrderProducts;