import React from 'react';
import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux'
import _ from 'lodash';


import MailBox from '../../components/mailBox/MailBox.component';

import { updateMailBox } from '../../actions/mailBox/mailBox.actions';

const MailBoxContainer = ({ updateMailBox, mailConfig }) => {
    const { t } = useTranslation();

    const handleEditMailbox = (values) => {
        updateMailBox(values)
    }

    return (
        <div className="col">
            <div className="row">
                <div className="col-12">
                    <h1>{t('mailBox')}</h1>
                </div>
                {
                    mailConfig &&
                    <MailBox
                        mailConfig={mailConfig}
                        onSubmit={(val) => handleEditMailbox(val)}
                    />
                }

            </div>
        </div>
    )
}

const mapStateToProps = (store) => ({
    mailConfig: _.find(_.get(store, 'app.settings'), (o) => {
        return o.type === 'mailConfig'
    }),
});

const mapDispatchToProps = (dispatch) => {
    return {
        updateMailBox: (values) => dispatch(updateMailBox(values))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MailBoxContainer);