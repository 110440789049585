import {
    SET_PRODUCT_CATEGORIES_TREE,
    SET_PRODUCTS,
    INCREASE_OFFSET,
    DECREASE_OFFSET,
    SET_OFFSET,
    RESET_OFFSET,
    CLEAR_PRODUCTS,
    SET_PRODUCTS_QUERY,
    SET_PRODUCTS_CATEGORY,
    RESET_PRODUCTS_CATEGORY
} from '../actions/products.actions';

const defaultState = {
    params: {
        limit: 24,
        offset: 0,
        query: ''
    },
    productCategoriesTree: null,
    products: null,
    productsTotal: 0,
    cart: []
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case SET_PRODUCT_CATEGORIES_TREE:
            return {
                ...state,
                productCategoriesTree: action.productCategoriesTree
            }
        case SET_PRODUCTS:
            return {
                ...state,
                products: action.products,
                productsTotal: action.productsTotal
            }
        case INCREASE_OFFSET:
            return {
                ...state,
                params: {
                    ...state.params,
                    offset: state.params.offset + state.params.limit
                }
            }
        case DECREASE_OFFSET:
            return {
                ...state,
                params: {
                    ...state.params,
                    offset: state.params.offset - state.params.limit
                }
            }
        case SET_OFFSET:
            return {
                ...state,
                params: {
                    ...state.params,
                    offset: action.newOffset
                }
            }
        case RESET_OFFSET:
            return {
                ...state,
                params: {
                    ...state.params,
                    offset: 0
                }
            }
        case CLEAR_PRODUCTS:
            return {
                ...state,
                products: null,
                productsTotal: 0,
                params: {
                    ...state.params,
                    query: '',
                    category: null
                }
            }
        case SET_PRODUCTS_QUERY:
            return {
                ...state,
                params: {
                    ...state.params,
                    query: action.query
                }
            }
        case SET_PRODUCTS_CATEGORY:
            return {
                ...state,
                params: {
                    ...state.params,
                    category: action.id
                }
            }
        case RESET_PRODUCTS_CATEGORY:
            return {
                ...state,
                params: {
                    ...state.params,
                    category: null
                }
            }
        default:
            return state;
    }
}