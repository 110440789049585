import React from 'react';

import {
    Route,
    Redirect
} from "react-router-dom";

const PrivateRoute = ({ children, user, role, roles, isLoading, ...rest }) => {
    let isAllowed = false;
    if (roles) {
        isAllowed = isLoading || (user && (user.type === "admin" || user.type === role || (roles && roles.includes(user.type))));
    } else {
        isAllowed = isLoading || (user && (user.type === "admin" || user.type === role));
    }


    return (
        <Route
            {...rest}
            render={() =>
                isAllowed && children

                // isAllowed ? (
                //     children
                // ) : (
                //         <Redirect
                //             to={{
                //                 pathname: "/",
                //             }}
                //         />
                //     )
            }
        />
    );
}

export default PrivateRoute;