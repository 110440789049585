import React, { } from 'react';
import { useTranslation } from 'react-i18next';
import './productQuantity.css';

const ProductQuantity = ({ value, handleSubtract, handleAdd }) => {
    const { t } = useTranslation();
    return (
        <div className="quantity">
            <span className="quantity__control quantity__control--dec mr-4"
                onClick={() => handleSubtract()}
            >-</span>
            <span className="quantity__number">{value}</span>
            <span className="quantity__unit ml-2">{t('pcs')}</span>
            <span className="quantity__control quantity__control--inc ml-4"
                onClick={() => handleAdd()}
            >+</span>
        </div>
    )
}

export default ProductQuantity;