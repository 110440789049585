import Api from '../../services/api';
import { isLoading, displayErrors } from '../../application/actions/application.actions';

const NAMESPACE = "PRODUCT";
export const SET_PRODUCT = `SET_PRODUCT_${NAMESPACE}`;
export const SET_VARIANT = `SET_VARIANT_${NAMESPACE}`;
export const CLEAR_PRODUCT = `CLEAR_PRODUCT_${NAMESPACE}`;


export const getVariant = (id) => dispatch => {
    dispatch(isLoading(true));

    Api.get(`product/variant/${id}`).then(res => {
        if (res.success) {
            dispatch(setVariant(res.document));
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}
export const getProduct = (id) => dispatch => {
    dispatch(isLoading(true));

    Api.get(`product/product/${id}`).then(res => {
        if (res.success) {
            dispatch(setProduct(res.document));
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

export const setProduct = (product) => dispatch => {
    dispatch({
        type: SET_PRODUCT,
        product
    })
}
export const setVariant = (variant) => dispatch => {
    dispatch({
        type: SET_VARIANT,
        variant
    })
}

export const clearProduct = () => dispatch => {
    dispatch({
        type: CLEAR_PRODUCT
    })
}