import Api from '../../services/api';
import { isLoading, displayErrors } from '../../application/actions/application.actions';
import moment from 'moment';

const NAMESPACE = "INVOICES";
export const SET_INVOICES = `SET_INVOICES_${NAMESPACE}`;
export const SET_INVOICES_QUERY = `SET_INVOICES_QUERY_${NAMESPACE}`;
export const SET_INVOICES_FILTER = `SET_INVOICES_FILTER_${NAMESPACE}`;
export const CLEAR_INVOICES = `CLEAR_INVOICES_${NAMESPACE}`;
export const DECREASE_OFFSET = `DECREASE_OFFSET_${NAMESPACE}`;
export const INCREASE_OFFSET = `INCREASE_OFFSET_${NAMESPACE}`;
export const RESET_OFFSET = `RESET_OFFSET_${NAMESPACE}`;
export const SET_OFFSET = `SET_OFFSET_${NAMESPACE}`;
export const SET_INVOICES_LTE = `SET_INVOICES_LTE_${NAMESPACE}`;
export const SET_INVOICES_GTE = `SET_INVOICES_GTE_${NAMESPACE}`;

export const getInvoices = () => (dispatch, getState) => {
    dispatch(isLoading(true));

    const params = { ...getState().invoices.params };

    if (params && params.query !== '') {
        // params[`_id[orin]`] = params.query;
        // params[`customer.name[orin]`] = params.query;
        params[`number[orin]`] = params.query;
    }
    if (params && params.filter && params.filter.value !== null) {
        params[`status`] = params.filter.value;
    }

    if(params && params['createdAt[lte]']){
        params['createdAt[lte]'] = moment(params['createdAt[lte]']).endOf('day').format('X')
    }
    if(params && params['createdAt[gte]']){
        params['createdAt[gte]'] = moment(params['createdAt[gte]']).startOf('day').format('X')
    }

    Api.get(`invoice/invoice`, params).then(res => {
        if (res.success) {
            dispatch(setInvoices(res.documents, res.total))
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

export const setInvoices = (invoices, total) => dispatch => {
    dispatch({
        type: SET_INVOICES,
        invoices,
        total
    })
}

export const setInvoicesQuery = (query) => dispatch => {
    dispatch({
        type: SET_INVOICES_QUERY,
        query
    })
    dispatch(resetOffset());
    dispatch(getInvoices());
}

export const setInvoicesFilter = (filter) => dispatch => {
    dispatch({
        type: SET_INVOICES_FILTER,
        filter
    })
    dispatch(resetOffset());
    dispatch(getInvoices());
}

export const invoicesNextPage = () => (dispatch) => {
    dispatch({
        type: INCREASE_OFFSET
    })
    dispatch(getInvoices())
}

export const invoicesPrevPage = () => (dispatch) => {
    dispatch({
        type: DECREASE_OFFSET
    })
    dispatch(getInvoices())
}

export const resetOffset = () => dispatch => {
    dispatch({
        type: RESET_OFFSET
    })
}

export const goToInvoicesPage = (page) => (dispatch, getState) => {
    const limit = getState().invoices.params.limit;
    const newOffset = (page - 1) * limit;

    dispatch({
        type: SET_OFFSET,
        newOffset
    })

    dispatch(getInvoices())
}

export const clearInvoices = () => dispatch => {
    dispatch({
        type: CLEAR_INVOICES
    })
}

export const filterByDate = (type, date) => dispatch => {
    if (type === 'lte') {
        dispatch({
            type: SET_INVOICES_LTE,
            date
        })
    } else {
        dispatch({
            type: SET_INVOICES_GTE,
            date
        })
    }

    dispatch(getInvoices())
}
