import React, { useEffect } from 'react';
import {
    useHistory
} from "react-router-dom";
import { connect } from 'react-redux'

import Header from '../../components/header/Header.component';

import { saveHistoryToReducer, logout, getApplicationSettings } from '../../actions/application.actions';
import { clearCartInLocalStorage, clearCart } from '../../../cart/actions/cart.actions';

const HeaderContainer = (props) => {
    const { saveHistoryToReducer, getApplicationSettings } = props;
    const history = useHistory();

    useEffect(() => {
        getApplicationSettings();
    }, [getApplicationSettings])

    if (history) {
        saveHistoryToReducer(history);
    }
    return (

        <Header {...props} />
    )
}

const mapStateToProps = (state) => ({
    user: state.app.user,
    cart: state.cart.cart,
    history: state.app.history
});

const mapDispatchToProps = (dispatch) => {
    return {
        saveHistoryToReducer: (history) => dispatch(saveHistoryToReducer(history)),
        clearCartInLocalStorage: () => clearCartInLocalStorage(),
        clearCart: () => dispatch(clearCart()),
        logout: () => dispatch(logout()),
        getApplicationSettings: () => dispatch(getApplicationSettings()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);