import React from 'react';
import { Link } from 'react-router-dom';

import Koszyk from '../../../assets/icons/koszyk.svg';
import Usun from '../../../assets/icons/usun.svg';

const HeaderBasket = ({ clearCartInLocalStorage, clearCart, cart }) => {

    const handleClearBasket = () => {
        clearCartInLocalStorage();
        clearCart();
    }
    return (
        <div className="minicart badge badge-pill bg-white   px-4 ml-4 py-2">
            <div className="row align-items-center">
                <div className="col-auto">
                    <Link to="/cart" className="minicart__link">
                        <img className="minicart__icon mb-2" src={Koszyk} alt="" />
                        <span className="minicart__counter">{(cart) ? cart.length : 0}</span>
                    </Link>
                </div>
                <div className="col-auto">
                    <div className="minicart__remove cursor-pointer" onClick={() => { handleClearBasket() }}>
                        <img className="minicart__icon" src={Usun} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderBasket;