import React from 'react';
import { useTranslation } from 'react-i18next';

const AccountDetails = ({ name, email, phone, nip }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>

            {name &&
                <div className="col-12 mt-4">
                    <div className="account__name">
            <p className="account__label">{t('name')}</p>
                        <p className="account__value">{name}</p>
                    </div>
                </div>
            }

            {email &&
                <div className="col-12 mt-4">
                    <div className="account__mail">
                        <p className="account__label">E-mail</p>
                        <p className="account__value">{email}</p>
                    </div>
                </div>
            }

            {phone &&
                <div className="col-12 mt-4">
                    <div className="account__phone">
                        <p className="account__label">{t('phone')}</p>
                        <p className="account__value">{phone}</p>
                    </div>
                </div>
            }

            {nip &&
                <div className="col-12 mt-4">
                    <div className="account__nip">
                        <p className="account__label">{t('nip')}</p>
                        <p className="account__value">{nip}</p>
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default AccountDetails;