import React from 'react';

import i18n from '../../../i18n';

const HeaderLanguage = () => {
    const handleLanguageChange = (language) => {
        i18n.changeLanguage(language);
        localStorage.setItem('language', language);
    }

    const selectedLanguage = () => {
        const language = localStorage.getItem('language');
        switch (language) {
            case 'pl':
                return 'PL';
            case 'en':
                return 'EN';
            case 'sk':
                return 'SK';
            default:
                return 'PL';
        }
    }

    return (
        <div className="col-auto m-5">
            <div className="lang">
                <div className="dropdown dropdown--lang">
                    <div className="dropdown-toggle" type="button" id="dropdownLang" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {selectedLanguage()}
                    </div>
                    <div className="dropdown-menu" aria-labelledby="dropdownLang">
                        <button className="dropdown-item" href="#" onClick={() => handleLanguageChange('pl')}>PL</button>
                        <button className="dropdown-item" href="#" onClick={() => handleLanguageChange('en')}>EN</button>
                        <button className="dropdown-item" href="#" onClick={() => handleLanguageChange('sk')}>SK</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderLanguage;