import React from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import './cartSummary.css';

import CartItems from '../components/CartItems.component';
// import CartAddress from '../components/CartAddress.component';
// import TextInput from '../../application/components/formComponents/TextInput';

import { addProductToCart, removeProductFromCart } from '../actions/cart.actions';
import { saveOrder } from '../../order/actions/order.actions';

const totalPriceNetto = (products, showPrices) => {
    let total = 0;
    if (!products) return;

    products.forEach(p => {
        if (typeof showPrices !== "undefined" && !showPrices) {
            if (p.priceBeforeDiscount && p.amount) {
                total += (p.priceBeforeDiscount * p.amount)
            } else if (p.price && p.amount) {
                total += (p.price * p.amount)
            }
        } else {
            if (p.price && p.amount) {
                total += (p.price * p.amount)
            }
        }
    })

    return total.toFixed(2);
}
const totalPriceBrutto = (products, showPrices) => {
    let total = 0;
    if (!products) return;

    products.forEach(p => {
        if (typeof showPrices !== "undefined" && !showPrices) {
            if (p.priceBruttoBeforeDiscount && p.amount) {
                total += (p.priceBruttoBeforeDiscount * p.amount)
            } else if (p.priceBrutto && p.amount) {
                total += (p.priceBrutto * p.amount)
            }
        } else {
            if (p.priceBrutto && p.amount) {
                total += (p.priceBrutto * p.amount)
            }
        }
    })

    return total.toFixed(2);
}



const CartSummary = ({ userCurrency, address, userName, cart, addProductToCart, removeProductFromCart, description, saveOrder, showPrices }) => {
    const { t } = useTranslation();

    const handleSaveOrder = () => {
        const order = {
            description: description,
            address: address,
            products: cart
        }

        saveOrder(order);
    }

    return (
        <section id="cartSummary" className="cart-summary content">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Link to="/cart" className="summary__back"><span className="mr-3">{'<'}</span>{t('returnToBasket')}</Link>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-12">
                        <h1>{t('summary')}</h1>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-12">
                        <button className="btn btn--secondary" type="button" data-toggle="collapse" data-target="#summaryItems" aria-expanded="false" aria-controls="summaryItems">{t('checkOrder')}</button>
                    </div>
                    <div className="col-12">
                        <div className="summary__items collapse mt-5" id="summaryItems">
                            <CartItems
                                products={cart}
                                addProductToCart={addProductToCart}
                                removeProductFromCart={removeProductFromCart}
                                showPrices={showPrices}
                            />
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-12">
                        <div className="summary__total">
                            <h3>{t('total')}</h3>
                            <p className="total__price total__price--netto">{totalPriceNetto(cart, showPrices)} {userCurrency} netto</p>
                            <p className="total__price total__price--brutto">{totalPriceBrutto(cart, showPrices)} {userCurrency} brutto</p>
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-12">
                        <div className="summary__address">
                            <h3>{t('shippingAddress')}</h3>
                            <p>{userName}<br />
                                {_.get(address, 'street')} {_.get(address, 'streetNumber')}<br />
                                {_.get(address, 'postCode')} {_.get(address, 'locality')}</p>
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-12">
                        <div className="summary__comment">
                            <h3>{t('orderDescription')}</h3>
                            <p>{(description) ? description : '-'} </p>
                        </div>
                    </div>
                </div>

                <div className="summary__submit mt-50 mb-50">
                    <div className="row justify-content-center">
                        <div className="col-auto">
                            <button className="btn btn--primary"
                                onClick={() => handleSaveOrder()}
                            >{t('orderAction')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = (store) => ({
    cart: store.cart.cart,
    address: store.cart.address,
    description: store.cart.description,
    userName: _.get(store, 'app.user.name'),
    userCurrency: _.get(store, 'app.user.currency'),
    showPrices: _.get(store, 'app.user.showPrices')
});

const mapDispatchToProps = (dispatch) => {
    return {
        saveOrder: (order) => dispatch(saveOrder(order)),
        addProductToCart: (product, amount, isFromLocalStorage) => dispatch(addProductToCart(product, amount, isFromLocalStorage)),
        removeProductFromCart: (product, amount) => dispatch(removeProductFromCart(product, amount)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CartSummary);