import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash'
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment'

import './order.css';

import OrderProducts from '../components/OrderProducts.component';


import { getOrder, clearOrder } from '../actions/order.actions';

const OrderContainer = ({ getOrder, order, clearOrder, showPrices }) => {
    const params = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        if (params && params.id) {
            getOrder(params.id);
        }

        return () => {
            clearOrder();
        }
    }, [getOrder, clearOrder])

    if (!order) return null;

    const orderTotal = () => {
        if (typeof showPrices !== "undefined" && !showPrices && _.get(order, 'priceBeforeDiscount', null)) {
            return (
                <React.Fragment>
                    {
                        _.get(order, 'priceBeforeDiscount', null) &&
                        <p className="total__price total__price--netto">{_.get(order, 'priceBeforeDiscount', 0).toFixed(2)} {_.get(order, 'products[0].currency')} netto</p>
                    }

                    {
                        _.get(order, 'priceBruttoBeforeDiscount', null) &&
                        <p className="total__price total__price--brutto">{_.get(order, 'priceBruttoBeforeDiscount', 0).toFixed(2)} {_.get(order, 'products[0].currency')}  brutto</p>
                    }

                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <p className="total__price total__price--netto">{_.get(order, 'price', 0).toFixed(2)} {_.get(order, 'products[0].currency')} netto</p>
                <p className="total__price total__price--brutto">{_.get(order, 'priceBrutto', 0).toFixed(2)} {_.get(order, 'products[0].currency')}  brutto</p>
            </React.Fragment>
        )

    }

    const renderDate = () => {
        if (order && order.status === "rejected") {
            return (
                <div className="col-12">
                    <h3> {t('rejectionDate')}  <Moment format="DD/MM/YYYY">{_.get(order, 'rejectDate')}</Moment></h3>
                </div>

            )
        } else if (order && order.status === "completed") {
            return (
                <div className="col-12">
                    <h3> {t('completionDate')}  <Moment format="DD/MM/YYYY">{_.get(order, 'createdAt')}</Moment></h3>
                </div>
            )

        }
    }

    return (
        <section id="order" className="order content">
            <div className="container">
                <div className="row mt-5">
                    <div className="col-12">
                        <h1>{t('order')} {_.get(order, 'orderNumber', params.id)}</h1>
                    </div>

                    {renderDate()}

                </div>
                <div className="row">
                    <div className="col-12">
                        <OrderProducts
                            products={_.get(order, 'products')}
                            showPrices={showPrices}
                        />
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-12">
                        <div className="order__total">
                            <h3>{t('total')}</h3>
                            {orderTotal()}
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-12">
                        <div className="order__address">
                            <h3>{t('shippingAddress')}</h3>
                            <p>{_.get(order, 'customer.name')}<br />
                                {_.get(order, 'address.street')} {_.get(order, 'address.streetNumber')}<br />
                                {_.get(order, 'address.postCode')} {_.get(order, 'address.locality')}</p>
                        </div>
                    </div>
                </div>

                {/* <div className="row mt-5">
                    <div className="col-12">
                        <div className="order__date">
                            <h3>Termin dostawy</h3>
                            <p>31-07-2020</p>
                        </div>
                    </div>
                </div> */}
                {
                    (order && order.description) &&
                    <div className="row mt-5">
                        <div className="col-12">
                            <div className="order__comment">
                                <h3>{t('orderDescription')}</h3>
                                <p> {_.get(order, 'description')}</p>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </section>
    )
}

const mapStateToProps = (store) => ({
    order: store.order.order,
    showPrices: _.get(store, 'app.user.showPrices')
});

const mapDispatchToProps = (dispatch) => {
    return {
        getOrder: (id) => dispatch(getOrder(id)),
        clearOrder: () => dispatch(clearOrder()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderContainer);