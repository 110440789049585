import {
    SET_ORDERS,
    SET_ORDERS_QUERY,
    SET_ORDERS_FILTER,
    CLEAR_ORDERS,
    INCREASE_OFFSET,
    DECREASE_OFFSET,
    SET_OFFSET,
    RESET_OFFSET
} from '../actions/sellerOrders.actions';

const defaultState = {
    params: {
        limit: 20,
        offset: 0,
        query: '',
        filter: null,
        'sort[createdAt]': -1,
    },
    orders: [],
    ordersTotal: 0
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case SET_ORDERS:
            return {
                ...state,
                orders: action.orders,
                ordersTotal: action.total
            }
        case SET_ORDERS_QUERY:
            return {
                ...state,
                params: {
                    ...state.params,
                    query: action.query
                }
            }
        case SET_ORDERS_FILTER:
            return {
                ...state,
                params: {
                    ...state.params,
                    filter: action.filter
                }
            }
        case INCREASE_OFFSET:
            return {
                ...state,
                params: {
                    ...state.params,
                    offset: state.params.offset + state.params.limit
                }
            }
        case DECREASE_OFFSET:
            return {
                ...state,
                params: {
                    ...state.params,
                    offset: state.params.offset - state.params.limit
                }
            }
        case SET_OFFSET:
            return {
                ...state,
                params: {
                    ...state.params,
                    offset: action.newOffset
                }
            }
        case RESET_OFFSET:
            return {
                ...state,
                params: {
                    ...state.params,
                    offset: 0
                }
            }
        case CLEAR_ORDERS:
            return defaultState;
        default:
            return state;

    }
}