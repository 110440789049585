import React from 'react';
import _ from 'lodash';

import Usun from '../../assets/icons/usun.svg';

import DatePicker from '../../application/components/datePicker/DatePicker.component';
import { useTranslation } from 'react-i18next';

const SellerSplitOrder = ({ orders, handleSelectProduct, handleAddSplitOrder, handleDeleteSplitOrder, handleChangeSplitOrderDate, handleAddDescriptionToSplitOrders, handleSendSplitOrder }) => {
    const { t } = useTranslation();

    const renderProducts = (products, orderIndex) => {
        if (!products) return;

        return products.map((p, i) => {
            return (
                <tr key={i}>
                    <td>
                        <div className="order__name d-flex align-items-center justify-content-center">
                            <input
                                type="checkbox"
                                checked={(p.checked) ? true : false}
                                onChange={(e) => handleSelectProduct(i, orderIndex, { checked: e.target.checked })}
                            />
                        </div>
                    </td>
                    <td>
                        <div className="order__name d-flex align-items-center justify-content-center">
                            <span className="ml-3">{i + 1}. {_.get(p, 'name.pl')}</span>
                        </div>
                    </td>
                    <td>
                        <div className="order__netto">
                            <p>{_.get(p, 'price', 0).toFixed(2)} {_.get(p, 'currency')} netto</p>
                        </div>
                    </td>
                    <td>
                        <div className="order__brutto">
                            <p>{_.get(p, 'priceBrutto', 0).toFixed(2)} {_.get(p, 'currency')} brutto</p>
                        </div>
                    </td>
                    <td>
                        <div className="order__quantity">
                            <input
                                type="number"
                                min={1}
                                style={{ border: '1px solid gray' }}
                                value={_.get(p, 'amount')}
                                onChange={(e) => handleSelectProduct(i, orderIndex, { amount: e.target.value })}
                            />
                        </div>
                    </td>
                    {/*  */}
                    <td>
                        <div className="order__stock">
                            <p className="text-center">{_.get(p, 'warehouseAmount')}</p>
                        </div>
                    </td>
                </tr>
            )
        })
    }

    const renderOrders = () => {
        return orders.map((o, i) => {
            if(!o.orderDate){
                o.orderDate = new Date();
            } else {
                o.orderDate = new Date(o.orderDate);
            }
            return (
                <React.Fragment key={i}>
                    <div className="row mt-4">
                        <div className="col-12">
                            <h4>{t('order')} {i + 1}</h4>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-lg-8">
                            <div className="table-responsive rounded-box p-4">
                                <table className="table order__table--more">
                                    <thead>
                                        <tr>
                                            <th scope="col">{t('addAction')}</th>
                                            <th scope="col">{t('title')}</th>
                                            <th scope="col">{t('price')} netto</th>
                                            <th scope="col">{t('price')} brutto</th>
                                            <th scope="col" className="text-center">{t('amount')}</th>
                                            <th scope="col" className="text-center">{t('stock')}</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {renderProducts(o.products, i)}
                                    </tbody>
                                </table>
                            </div>
                        </div>


                        <div className="col col-lg-3 md-lg-0">
                            <div className="rounded-box p-5 h-100 d-flex justify-content-center flex-column">
                                <h4>Data realizacji</h4>
                                <DatePicker
                                    date={o.orderDate}
                                    setDate={(date) => handleChangeSplitOrderDate(i, date)}
                                />
                            </div>
                        </div>
                        <div className="col-auto col-lg md-lg-0">
                            <div className="order__delete cursor-pointer rounded-box p-4 h-100 d-flex align-items-center justify-content-center flex-column"
                                onClick={() => handleDeleteSplitOrder(i)}
                            >
                                <img src={Usun} alt="" />
                            </div>
                        </div>
                    </div>

                </React.Fragment>
            )
        })
    }

    return (
        <div id="orderSplit" className="order__split collapse mt-50">

            {renderOrders()}

            <div className="row mt-5">
                <div className="col-auto">
                    <button className="btn btn--primary"
                        onClick={() => handleAddSplitOrder()}
                    >Dodaj kolejne zamówienie</button>
                </div>
            </div>

            <div className="row mt-50">
                <div className="col-12">
                    <h4>Komentarz do zamówienia</h4>
                    <textarea className="mt-4" onChange={(e) => handleAddDescriptionToSplitOrders(e.target.value)}></textarea>
                </div>
            </div>

            <div className="row mt-50 justify-content-center">
                <div className="col-auto">
                    <button className="btn btn--primary"
                        onClick={() => handleSendSplitOrder()}
                    >Wyślij wszystkie zamówienia</button>
                </div>
            </div>
        </div>
    )
}

export default SellerSplitOrder;