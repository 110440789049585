import React, { useEffect } from 'react';
// import { useParams } from 'react-router-dom';
import _ from 'lodash'
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isAuth } from '../../../application/actions/application.actions';

const WelcomePage = ({ isAuth}) => {
    const { t } = useTranslation();
    useEffect(() => {
        isAuth();
    }, [])

    return (
        <section id="invoice" className="invoice content d-flex justify-content-center align-items-center">
            <h1>
                {t('welcomeTob2b')}
            </h1>
        </section>
    )
}

const mapStateToProps = (store) => ({

});

const mapDispatchToProps = (dispatch) => {
    return {
        isAuth: () => dispatch(isAuth())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage);