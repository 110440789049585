import React from 'react';
import { Link } from "react-router-dom";
import _ from 'lodash';

const CategoriesTreeItem = ({ name, children, id, hrefId, selectCategory, category, selectedCategories }) => {

    const renderItems = () => {
        return children.map(e => {
            const hrefId = e._id.replace(/[0-9]/g, '');
            return <CategoriesTreeItem
                key={e._id}
                id={e._id}
                hrefId={hrefId}
                name={e.name.pl}
                children={e.children}
                selectCategory={selectCategory}
                category={e}
            />
        })
    }

    const isCategoryChecked = () => {
        if (!selectedCategories) return;
        let isChecked = false;

        selectedCategories.forEach((cat) => {
            if (cat.foreignId === id) {
                isChecked = true;
            }
        })
        return isChecked;
    }


    if (children && children.length > 0) {
        return (
            <React.Fragment>
                <li className="nav__item " role="button" >

                    <div className="nav__link"
                        onClick={(() => selectCategory(category))}
                    >
                        {name}
                    </div>
                    <a className="nav__link" data-toggle="collapse" href={`#${hrefId}Dropdown`} role="button" aria-expanded="false"
                        aria-controls={`${hrefId}Dropdown`}><span className="dropdown__chevron"
                        // onClick={() => saveExpandedCategoryToLocalStorage(id)}
                        ></span></a>
                </li>
                <li id={`${hrefId}Dropdown`} className={`collapse `}>
                    <ul className="nav__dropdown flex-column">
                        {renderItems()}
                    </ul>
                </li>
            </React.Fragment>
        )
    }

    return (
        <li className="nav__item "  role="button">

            <div className="nav__link"
                onClick={(() => selectCategory(category))}
            >
                {name}
            </div>
        </li>
    )
}

export default CategoriesTreeItem;