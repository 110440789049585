import React from 'react';

const TextInput = (props) => {
    const { id, type, onChange, label, errors, size, value, min, max, disabled } = props;

    const inputSize = () => {
        switch (size) {
            case 'medium':
                return 'col-md-6';
            case 'small':
                return 'col-md-3'
            default:
                return 'col-12'
        }
    }
    return (
        <div className={`
        ${inputSize()}
       
        input${errors ? '--error' : ""}`}>
            <label >{label}</label>
            <input
                type={type}
                className="border--light"
                name="login"
                onChange={(e) => (id) ? onChange(id, e.target.value) : onChange(e.target.value)}
                value={value}
                min={min}
                max={max}
                disabled={disabled}
            />
            {errors && <div className="error__message">{errors}</div>}
        </div>
    )
}

export default TextInput;