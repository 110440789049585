import React from 'react';
import { connect } from 'react-redux'

import AdminClient from '../../components/clients/AdminClient.component';
import AdminClients from '../../components/clients/AdminClients.component';

import { getClients } from '../../actions/clients/adminClients.actions';
import { getSellers } from '../../actions/sellers/adminSellers.actions';
import { createClientAccount, editClientAccount, getExternalClients, getClient, changeIsActiveClient, deleteClientAccount, clearClient } from '../../actions/clients/adminClient.actions';

const AdminClientModule = (props) => {

    return (
        <React.Fragment>
            <AdminClient
                createClientAccount={props.createClientAccount}
                editClientAccount={props.editClientAccount}
                externalClients={props.externalClients}
                getExternalClients={props.getExternalClients}
                getClient={props.getClient}
                client={props.client}
                deleteClientAccount={props.deleteClientAccount}
                history={props.history}
                clearClient={props.clearClient}
                getSellers={props.getSellers}
                sellers={props.sellers}
            />
            <AdminClients
                getClients={props.getClients}
                clients={props.clients}
                changeIsActiveClient={props.changeIsActiveClient}
                deleteClientAccount={props.deleteClientAccount}
            />
        </React.Fragment>
    )
}

const mapStateToProps = (store) => ({
    externalClients: store.admin.externalClients,
    clients: store.admin.clients,
    client: store.admin.client,
    history: store.app.history,
    sellers: store.admin.sellers,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getExternalClients: (query) => dispatch(getExternalClients(query)),
        getClients: (query) => dispatch(getClients(query)),
        createClientAccount: (user) => dispatch(createClientAccount(user)),
        editClientAccount: (id, user) => dispatch(editClientAccount(id, user)),
        getClient: (id) => dispatch(getClient(id)),
        changeIsActiveClient: (value, id) => dispatch(changeIsActiveClient(value, id)),
        deleteClientAccount: (id) => dispatch(deleteClientAccount(id)),
        clearClient: () => dispatch(clearClient()),
        getSellers: (query) => dispatch(getSellers(query)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminClientModule);