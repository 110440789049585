import React from 'react';
import { useTranslation } from 'react-i18next';

import CategoriesTreeItem from './CategoriesTreeItem.component';

const CategoryPicker = ({ productCategoriesTree, selectCategory, selectedCategories }) => {
    const { t } = useTranslation();

    const renderTreeItems = () => {
        if (productCategoriesTree) {
            return productCategoriesTree.map(e => {
                const hrefId = e._id.replace(/[0-9]/g, '');
                return (
                    <CategoriesTreeItem
                        key={e._id}
                        id={e._id}
                        hrefId={hrefId}
                        name={e.name.pl}
                        children={e.children}
                        selectCategory={selectCategory}
                        category={e}
                        selectedCategories={selectedCategories}
                    />
                )
            })
        }
    }

    return (
        <React.Fragment>
            <div className="row mt-5">
                <div className="col-12">
                    <h4>{t('addCategories')}</h4>
                </div>
            </div>
            <div>
                {renderTreeItems()}
            </div>
        </React.Fragment>

    )
}

export default CategoryPicker;