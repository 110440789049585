import React, { useEffect } from 'react';

import './header.css';

import HeaderCells from './HeaderCells.component';
import HeaderLogo from './HeaderLogo.component';

const Header = ({ user, clearCartInLocalStorage, clearCart, cart, logout, history }) => {
    useEffect(() => {
        //boostrap burger mechanics
        let burger = document.querySelector('.hamburger');
        if (burger) {
            let mobileInner = document.querySelector('.header-mobile__inner');
            burger.addEventListener('click', function () {
                burger.classList.toggle("is-active");
                mobileInner.classList.toggle("show-menu");
            })
        }
    }, [])

    return (
        <React.Fragment>
            {/* Desktop */}
            <section id="header" className="header header--seller d-none d-xl-block">
                <div className="container">
                    <div className="d-flex row justify-content-between align-items-center">
                        <HeaderLogo
                            history={history}
                        />
                        <div className="col-auto">
                            <div className="d-flex row menu align-items-center">
                                <HeaderCells
                                    user={user}
                                    clearCart={clearCart}
                                    clearCartInLocalStorage={clearCartInLocalStorage}
                                    cart={cart}
                                    logout={logout}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            {/* Mobile */}
            <header id="headerMobile" className="header-mobile header py-5 d-block d-xl-none">
                <div className="container-fluid">
                    <div className="d-flex row justify-content-between">
                        <HeaderLogo />
                    </div>
                    <div className="hamburger hamburger--squeeze position-fixed bg--secondary">
                        <div className="hamburger-box">
                            <div className="hamburger-inner"></div>
                        </div>
                    </div>

                    {user &&
                        <div className="d-flex header-mobile__inner position-fixed bg--secondary pb-5">
                            <div className="header-mobile__hidden no-gutters">
                                <div className="d-flex row menu align-items-center justify-content-center pb-5">
                                    <HeaderCells
                                        mobile={true}
                                        clearCart={clearCart}
                                        clearCartInLocalStorage={clearCartInLocalStorage}
                                        cart={cart}
                                        logout={logout}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </header>
        </React.Fragment>
    )
}

export default Header;