import React from 'react';

import './footer.css'

import Telefon from '../../../assets/icons/telefon.svg';
import Mail from '../../../assets/icons/mail.svg';
import CloudLogo from '../../../assets/img/cloud-logo.png'

const Footer = () => {
    return (
        <footer id="footer" className="footer">
            {/* <div className="footer__top py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h3>Kontakt</h3>
                        </div>
                        <div className="col-md-4 d-flex align-items-center justify-content-center my-5">
                            <p className="h3 m-0 footer__name">Krzesła Sp. z o. o.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="d-flex align-items-center justify-content-center my-5">
                                <img src={Telefon} alt="" />
                                <div className="ml-4">
                                    <span>tel:</span>
                                    <p className="h3 footer__phone">+22 546 67 67</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="d-flex align-items-center justify-content-center my-5">
                                <img src={Mail} alt="" />
                                <div className="ml-4">
                                    <span>e-mail:</span>
                                    <p className="h3 footer__mail"><a href="mailto:biuro@krzesla.pl">biuro@krzesla.pl</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="footer__bottom pt-50 pb-50">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-auto">
                            <span className="mr-4">powered by</span><img src={CloudLogo} alt="" />
                        </div>
                        <div className="col-auto">
                            <span className="mr-1">infolinia: </span><span className="text--primary">+48 660 440 558</span>
                        </div>
                        <div className="col-auto">
                            <span className="mr-1">kontakt: </span><span><a href="mailto:it@over-cloud.pl" className="text--primary">it@over-cloud.pl</a></span>
                        </div>
                    </div>
                </div>
            </div>
        </footer >
    )
}

export default Footer;