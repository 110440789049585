import React from 'react';
import { useTranslation } from 'react-i18next';

import AutoComplete2 from '../../../application/components/formComponents/AutoComplete2';

const ProductPicker = ({ getProducts, products, handleProductSearch, handleProductAdd }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <div className="row mt-5">
                <div className="col-12">
                    <h4>{t('addProducts')}</h4>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-12">
                    <AutoComplete2
                        label={t('products')}
                        options={(products) ? products : null}
                        onChange={(product) => handleProductAdd(product)}
                        getFunction={(value) => getProducts(value)}
                        multiselect={true}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default ProductPicker;