import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './header.css';

import Produkty from '../../../assets/icons/produkty.svg';
import Faktury from '../../../assets/icons/faktury.svg';
import Zamowienia from '../../../assets/icons/zamowienia.svg';
import Kokpit from '../../../assets/icons/kokpit.svg';
import Konto from '../../../assets/icons/konto.svg';

import HeaderSingleCell from './HeaderSingleCell.component';
import HeaderBasket from './HeaderBasket.component';
import HeaderLogout from './HeaderLogout.component';
import HeaderLanguage from './HeaderLanguage.component';


const HeaderCells = ({ clearCartInLocalStorage, clearCart, mobile, user, cart, logout }) => {
    const [currentPath, setCurrentPath] = useState(window.location.pathname);

    useEffect(() => {
        setCurrentPath(window.location.pathname);
    }, [window.location.pathname])

    const { t } = useTranslation();

    const produktyCell = { title: t('products'), icon: Produkty, path: '/products' };
    const fakturyCell = { title: t('invoices'), icon: Faktury, path: '/invoices' };
    const zamowieniaCell = { title: t('orders'), icon: Zamowienia, path: (user && user.type === 'salesman') ? '/seller-orders' : '/orders' };
    const kokpitCell = { title: t('dashboard'), icon: Kokpit, path: (user && user.type === 'admin') ? '/admin' : '/dashboard' };
    const kontoCell = { title: t('myAccount'), icon: Konto, path: '/my-account' };
    const klienciCell = { title: t('clients'), icon: Konto, path: '/seller-clients' };

    const regularCells = [
        kokpitCell, produktyCell, fakturyCell, zamowieniaCell, , kontoCell
    ];
    const regularCellsNoInvoice = [
        kokpitCell, produktyCell, zamowieniaCell, , kontoCell
    ];

    const adminCells = [
        kokpitCell, produktyCell, kontoCell
    ];

    const sellerCells = [
        fakturyCell, zamowieniaCell, klienciCell
    ];

    const renderRoleSpecificCells = () => {
        if (user && user.type) {
            switch (user.type) {
                case 'company':
                    if (!user.showPrices) {
                        return regularCellsNoInvoice.map(cell => {
                            return (
                                <HeaderSingleCell title={cell.title} icon={cell.icon} path={cell.path} key={cell.title} mobile={mobile} active={(currentPath === cell.path) ? true : false} />
                            )
                        })
                    }
                    return regularCells.map(cell => {
                        return (
                            <HeaderSingleCell title={cell.title} icon={cell.icon} path={cell.path} key={cell.title} mobile={mobile} active={(currentPath === cell.path) ? true : false} />
                        )
                    })
                case 'salesman':
                    return sellerCells.map(cell => {
                        return (
                            <HeaderSingleCell title={cell.title} icon={cell.icon} path={cell.path} key={cell.title} mobile={mobile} active={(currentPath === cell.path) ? true : false} />
                        )
                    })
                case 'admin':
                    return adminCells.map(cell => {
                        return (
                            <HeaderSingleCell title={cell.title} icon={cell.icon} path={cell.path} key={cell.title} mobile={mobile} active={(currentPath === cell.path) ? true : false} />
                        )
                    })
                default:
                    return
            }
        }
    }
    return (
        <React.Fragment>
            {renderRoleSpecificCells()}

            {user &&
                <React.Fragment>

                    {
                        user && user.type === 'company' &&
                        <HeaderBasket
                            clearCart={clearCart}
                            clearCartInLocalStorage={clearCartInLocalStorage}
                            cart={cart}
                        />
                    }

                    <HeaderLogout
                        logout={logout}
                    />
                </React.Fragment>
            }

            <HeaderLanguage />


        </React.Fragment>
    )
}

export default HeaderCells;