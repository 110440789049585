import React from 'react';
import CartIcon from '../../../assets/icons/dodaj-do-koszyka.svg';

import './addToCart.css';

const AddToCart = ({ addProductToCart, item, amount }) => {
    return (
        <div className="add-to-cart" onClick={() => addProductToCart(item, amount)}>
            <img src={CartIcon} alt="" />
        </div>
    )
}

export default AddToCart;