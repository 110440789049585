import React from 'react';
import _ from 'lodash'
import { useTranslation } from 'react-i18next';

import ZamknijWhite from '../../../assets/icons/zamknij-white.svg';


const SelectedCategories = ({ selectedCategories, handleChangeCategoryDiscount, deleteCategory }) => {
    const { t } = useTranslation();

    const renderProducts = () => {
        if (!selectedCategories) return null;

        return selectedCategories.map((cat, i) => {
            return (
                <tr key={i}>
                    <td>{i + 1}. {_.get(cat, 'name.pl')}</td>
                    <td width="150px">
                        <input type="number" name="pricing__price" className="border--light"
                            value={(cat.discount) ? cat.discount : ''}
                            onChange={(e) => handleChangeCategoryDiscount(i, e.target.value)}
                        ></input>
                    </td>
                    <td width="150px">
                        <div className="client__action client__action--delete"  onClick={() => deleteCategory(cat)}>
                            <img src={ZamknijWhite} alt="" />
                        </div>
                    </td>
                </tr>
            )
        })
    }

    if (!selectedCategories || selectedCategories.length === 0) return null;


    return (
        <React.Fragment>
            <div className="col-12 mt-4">
                <div className="table-responsive">
                    <table className="table table-striped discounts__table">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">{t('discount')}</th>
                                <th scope="col">{t('deleteAction')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderProducts()}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SelectedCategories;