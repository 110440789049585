import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import ZamknijWhite from '../../../assets/icons/zamknij-white.svg';
import Edytuj from '../../../assets/icons/edytuj.svg';


import ConfirmationModal from '../../../application/components/modal/ConfirmationModal.component';

const Pricings = ({ pricings, deletePricing }) => {
    const { t } = useTranslation();

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);

    const handlePricingDelete = (id) => {
        setIdToDelete(id);
        setShowConfirmationModal(true);
    }

    const handleConfirmDeleteModal = () => {
        deletePricing(idToDelete);
        setShowConfirmationModal(false);
    }

    const handleCancelDeleteModal = () => {
        setIdToDelete(null);
        setShowConfirmationModal(false);
    }

    const renderPricings = () => {
        if (!pricings || pricings.length === 0) return;

        return pricings.map((o, i) => {
            return (
                <React.Fragment key={o._id}>
                    <tr >
                        <td><Link to={`/admin/pricing/${o._id}`}>{i + 1}. {_.get(o, 'name')}</Link></td>
                        <td width="100px">
                            <div className="client__action client__action--delete">
                                <Link to={`/admin/pricing/${o._id}`}> <img src={Edytuj} alt="" /></Link>
                            </div>
                        </td>
                        <td width="100px">
                            <div className="client__action client__action--delete" onClick={() => handlePricingDelete(o._id)}>
                                <img src={ZamknijWhite} alt=""  />
                            </div>
                        </td>
                    </tr>

                </React.Fragment>

            )
        })
    }

    return (
        <div className="row mt-5">
            {
                showConfirmationModal &&
                <ConfirmationModal
                    text={t('deletePricingQuestion')}
                    onConfirm={handleConfirmDeleteModal}
                    onCancel={handleCancelDeleteModal}
                />
            }
            <div className="col-12 mt-5">
                <div className="table-responsive">
                    {pricings && pricings.length > 0 &&
                        <table className="table table-striped ">
                            <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">{t('editAction')}</th>
                                    <th scope="col">{t('deleteAction')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderPricings()}
                            </tbody>
                        </table>}
                </div>
            </div>
        </div>
    )
}

export default Pricings;