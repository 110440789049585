import Api from '../../services/api';
import { isLoading, displayErrors } from '../../application/actions/application.actions';

const NAMESPACE = 'CLIENTS';

export const SET_CLIENTS = `SET_CLIENTS_${NAMESPACE}`;
export const SET_CLIENT = `SET_CLIENT_${NAMESPACE}`;

export const getClients = (searchQuery) => (dispatch, getState) => {
    const params = {...getState().admin.params};

    if (searchQuery && searchQuery !== "" && params) {
        params.select.split(",").forEach((field) => {
            params[`${field}[orin]`] = searchQuery;
        });
    }

    dispatch(isLoading(true));

    Api.get('salesman/user', params).then(res => {
        if (res.success) {
            dispatch(setClients(res.documents));
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

const setClients = (clients) => dispatch => {
    dispatch({
        type: SET_CLIENTS,
        clients
    })
}