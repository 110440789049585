import React, { useState } from 'react';

import ProductImage from './ProductImage.component';
import NoImage from '../../../assets/img/noimage.svg'

import './productImageCarousel.css';

const ProductImageCarousel = ({ images }) => {
    // const images = [`https://media.discordapp.net/attachments/742117356636995705/763161964225626122/uET7vI2fw2ZaRUYHWaM89iiUNp-EHCTL2mUr0IpkBr0.jpg?width=552&height=630`,
    //     `https://media.discordapp.net/attachments/687753863213613064/762021269285371944/120664816_142024390948637_8767324806052656111_n.jpg`];

    const [index, setIndex] = useState(0);

    const renderImages = () => {
        if (images && images.length > 0) {
            return images.map((img, i) => {
                return <div className={`carousel-item ${(i === index) ? 'active' : null}`} key={i}>
                    <div className="d-flex align-items-center justify-content-center">
                        <ProductImage
                            image={img}
                        />
                    </div>
                </div>
            })
        }

        return (
            <div className="d-flex align-items-center justify-content-center">
                <ProductImage
                    image={NoImage}
                />
            </div>

        )
    }

    const handleNextImage = () => {
        if (index >= images.length - 1) {
            setIndex(0);
        } else {
            setIndex(index + 1)
        }
    }
    const handlePreviousImage = () => {
        if (index === 0) {
            setIndex(images.length - 1);
        } else {
            setIndex(index - 1)
        }
    }

    return (
        <div className="product__carousel carousel slide" data-interval="false">
            <div className="carousel-inner">
                {renderImages()}
            </div>
            {images && images.length > 0 &&
                <React.Fragment>
                    <a className="carousel-control-prev pl-4" href="#prev" role="button" data-slide="prev"
                        onClick={() => handleNextImage()}
                    >
                        <span> {`<`} </span>
                    </a>
                    <a className="carousel-control-next pr-4" href="#next" role="button" data-slide="next"
                        onClick={() => handlePreviousImage()}
                    >
                        <span> {`>`} </span>
                    </a>
                </React.Fragment>
            }

        </div>
    )
}

export default ProductImageCarousel;