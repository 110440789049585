import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';


import './cartItems.css';
import Usun from '../../assets/icons/usun-czarna.svg'

import ProductImage from '../../application/components/product/ProductImage.component';
import ProductPrice from '../../application/components/product/ProductPrice.component';
import ProductQuantity from '../../application/components/product/ProductQuantity.component';

const Cart = ({ products, addProductToCart, removeProductFromCart, showPrices }) => {
    const { t } = useTranslation();

    const renderProducts = () => {
        if (!products) return;

        return products.map(p => {
            return (
                <div className="cart__item mt-5 pb-5 pb-md-0" key={p._id}>
                    <div className="row align-items-center justify-content-between">
                        <div className="col">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-md-7 col-xl-auto d-flex align-items-center">
                                    <ProductImage
                                        image={_.get(p, 'images[0]', null)}
                                    />
                                    <div className="ml-4">
                                        <h3>{_.get(p, 'name.pl')}</h3>
                                        <ProductPrice
                                            price={_.get(p, 'price')}
                                            discountedPrice={_.get(p, 'discountedPrice')}
                                            priceBeforeDiscount={_.get(p, 'priceBeforeDiscount')}
                                            currency={_.get(p, 'currency')}
                                            showPrices={showPrices}
                                        />
                                    </div>
                                </div>
                                <div className="col-auto offset">
                                    <ProductQuantity
                                        handleAdd={() => addProductToCart(p, 1)}
                                        handleSubtract={() => removeProductFromCart(p, 1)}
                                        value={p.amount}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-auto">
                            <span className="cart__remove" onClick={() => removeProductFromCart(p)}>
                                <img src={Usun} alt="" />
                            </span>
                        </div>
                    </div>
                </div>
            )
        })
    }

    const totalPrice = () => {
        let total = 0;
        if (!products) return;

        products.forEach(p => {
            if (typeof showPrices !== "undefined" && !showPrices) {
                if (p.priceBeforeDiscount && p.amount) {
                    total += (p.priceBeforeDiscount * p.amount)
                } else if (p.price && p.amount) {
                    total += (p.price * p.amount)
                }
            } else {
                if (p.price && p.amount) {
                    total += (p.price * p.amount)
                }
            }

        })

        return total.toFixed(2);
    }

    const basketTotal = () => {
        if (!products) return;

        return products.length;
    }


    return (
        <div className="rounded-box">
            <div className="cart__items p-5">
                <p>{t('numberOfProductsInBasket')}: <span className="cart__quantity">{basketTotal()}</span></p>

                {renderProducts()}

            </div>
            <div className="cart__total px-5 py-4 text-right">
                <p className="total__label h3 mb-0">{t('price')}</p>
                <p className="total__price">{totalPrice()} {_.get(products, '[0].currency', 'PLN')}</p>
            </div>
        </div>
    )
}

export default Cart;