import React from 'react';
import { useTranslation } from 'react-i18next';

import Check from '../../assets/icons/check.svg';
import Zamknij from '../../assets/icons/zamknij.svg';

const InvoiceStatus = ({ status }) => {
    const { t } = useTranslation();

    if (status === 1) {
        return (
            <div className="col-12 mt-5 d-flex align-items-center">
                <h3 className="mr-4" style={{marginBottom:0}}>Status: </h3>
                <div className="invoice__status--paid">
                    <img src={Check} alt="" className="mr-4" />
                    <span>{t('paid')}</span>
                </div>
            </div>
        )
    }

    return (
        <div className="col-12 mt-5 d-flex align-items-center">
            <h3 className="mr-4">Status: </h3>
            <div className="invoice__status">
                <img src={Zamknij} alt="" className="mr-4" />
                <span>{t('unpaid')}</span>
            </div>
        </div>
    )

}

export default InvoiceStatus;