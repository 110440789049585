import React from 'react';
import './chips.css';
import Zamknij from '../../../assets/icons/zamknij-black.svg';

const Chips = (props) => {
    const { item, onClick } = props;

    return (
        <div className="col-auto mt-4 pr-0">
            <span className="chips badge badge-pill badge--secondary" key={item.name}>{item.name}
             <img src={Zamknij} alt="" onClick={()=> onClick(item)}/>
             </span>
        </div>
    )
}

export default Chips;