import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    Link
} from "react-router-dom";

import TextInput from '../formComponents/TextInput';

const Login = (props) => {
    const { login } = props;
    const { t } = useTranslation();

    return (
        <section id="login" className="login content mt-50">
            <div className="container">
                <div className="d-flex row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="login__form">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="text-center">{t('signIn')}</h1>
                                </div>
                                <Formik
                                    initialValues={{ login: '', password: '' }}
                                    validationSchema={Yup.object({
                                        login: Yup.string()
                                            .required(`${t('loginRequired')}`)
                                            .email(`${t('invalidEmail')}`),
                                        password: Yup.string()
                                            .required(`${t('passwordError')}`)
                                    })}
                                    validateOnChange={false}
                                    validateOnBlur={false}
                                    onSubmit={(values) => {
                                        login(values.login, values.password)
                                    }}
                                >
                                    {formik => (
                                        <form className="row" onSubmit={formik.handleSubmit}>
                                            <TextInput
                                                id="login"
                                                label="Login"
                                                type="text"
                                                onChange={formik.setFieldValue}
                                                value={formik.values.login}
                                                errors={formik.errors.login}
                                            />
                                            <TextInput
                                                id="password"
                                                label={t('password')}
                                                type="password"
                                                onChange={formik.setFieldValue}
                                                value={formik.values.password}
                                                errors={formik.errors.password}
                                            />

                                            <div className="col-12 mt-4 text-right">
                                                <Link to="/lost-password" className="login__reset" >{t('lostPassword')}</Link>

                                            </div>
                                            {/* <div className="col-12 mt-4 d-flex align-items-center">
                                                <input type="checkbox" name="login__remember"></input>
                                                <span className="ml-3">{t('rememberMe')}</span>
                                            </div> */}
                                            <div className="col-12 mt-5 text-center">
                                                <button className="btn btn--primary" type="submit">{t('signInAction')}</button>
                                            </div>
                                        </form>
                                    )}
                                </Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Login;