import {
    SET_INVOICES,
    SET_INVOICES_QUERY,
    SET_INVOICES_FILTER,
    CLEAR_INVOICES,
    INCREASE_OFFSET,
    DECREASE_OFFSET,
    SET_OFFSET,
    RESET_OFFSET,
    SET_INVOICES_LTE,
    SET_INVOICES_GTE
} from '../actions/invoices.actions';

const defaultState = {
    params: {
        limit: 20,
        offset: 0,
        query: '',
        filter: null,
        'sort[createdAt]': -1,
        "createdAt[lte]": new Date(),
    },
    invoices: [],
    invoicesTotal: 0
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case SET_INVOICES:
            return {
                ...state,
                invoices: action.invoices,
                invoicesTotal: action.total
            }
        case SET_INVOICES_QUERY:
            return {
                ...state,
                params: {
                    ...state.params,
                    query: action.query
                }
            }
        case SET_INVOICES_FILTER:
            return {
                ...state,
                params: {
                    ...state.params,
                    filter: action.filter
                }
            }
        case INCREASE_OFFSET:
            return {
                ...state,
                params: {
                    ...state.params,
                    offset: state.params.offset + state.params.limit
                }
            }
        case DECREASE_OFFSET:
            return {
                ...state,
                params: {
                    ...state.params,
                    offset: state.params.offset - state.params.limit
                }
            }
        case SET_OFFSET:
            return {
                ...state,
                params: {
                    ...state.params,
                    offset: action.newOffset
                }
            }
        case RESET_OFFSET:
            return {
                ...state,
                params: {
                    ...state.params,
                    offset: 0
                }
            }
        case SET_INVOICES_LTE:
            return {
                ...state,
                params: {
                    ...state.params,
                    'createdAt[lte]': action.date
                }
            }
        case SET_INVOICES_GTE:
            return {
                ...state,
                params: {
                    ...state.params,
                    'createdAt[gte]': action.date
                }
            }
        case CLEAR_INVOICES:
            return defaultState;
        default:
            return state;

    }
}