import React from 'react';
import { Link } from "react-router-dom";
import _ from 'lodash';

const saveExpandedCategoryToLocalStorage = (id) => {
    let categories = getExpandedCategoriesFromLocalStorage();
    if (!categories) categories = [];

    const index = _.indexOf(categories, id);
    if (index < 0) {
        categories.push(id);
    } else {
        categories = _.without(categories, id)
    }
    localStorage.setItem('expandedCategories', JSON.stringify(categories));
}

const getExpandedCategoriesFromLocalStorage = () => {
    const categories = localStorage.getItem('expandedCategories');
    return JSON.parse(categories);
}

const isCategoryExpanded = (id) => {
    let categories = getExpandedCategoriesFromLocalStorage();
    const index = _.indexOf(categories, id);

    if (index < 0) return false;

    return true;
}

const ProductCategoriesTreeItem = ({ name, children, id, hrefId }) => {

    const renderItems = () => {
        return children.map(e => {
            const hrefId = e._id.replace(/[0-9]/g, '');
            return <ProductCategoriesTreeItem
                key={e._id}
                id={e._id}
                hrefId={hrefId}
                name={e.name.pl}
                children={e.children}
            />
        })
    }

    if (children && children.length > 0) {
        return (
            <React.Fragment>
                <li className={`nav__item ${(window.location.pathname === `/products/${id}`) ? 'active' : 'null'} `}>
                    <Link className="nav__link" to={`/products/${id}`}
                    >
                        {name}
                    </Link>
                    <a className="nav__link" data-toggle="collapse" href={`#${hrefId}Dropdown`} role="button" aria-expanded="false"
                        aria-controls={`${hrefId}Dropdown`}><span className="dropdown__chevron"
                            onClick={() => saveExpandedCategoryToLocalStorage(id)}
                        ></span></a>
                </li>
                <li id={`${hrefId}Dropdown`} className={`collapse ${(isCategoryExpanded(id) ? 'show' : null)}`}>
                    <ul className="nav__dropdown flex-column">
                        {renderItems()}
                    </ul>
                </li>
            </React.Fragment>
        )
    }
    return (
        <li className={`nav__item ${(window.location.pathname === `/products/${id}`) ? 'active' : 'null'}`}>
            <Link className="nav__link" to={`/products/${id}`}
            >
                {name}
            </Link>
        </li>
    )
}

export default ProductCategoriesTreeItem;