import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const LoyaltyPoints = ({ points, value, name }) => {
    const { t } = useTranslation();

    return (
        <div style={{flex: "0 45%" ,border: "1px solid #939393", margin: 8, padding: 16, backgroundColor: "white"}}>
            <p className="dashboard__balance">{t('name')}: {_.get(name, 'pl', name)}</p>
            <p className="dashboard__balance mt-5">{t('points')}: {points}</p>
            <p className="dashboard__balance mt-5">{t('monetaryValue')}: {value} {localStorage.getItem('currency')}</p>
        </div>
    )
}

export default LoyaltyPoints;