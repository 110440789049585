import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import Moment from 'react-moment'

// import Edytuj from '../../assets/icons/edytuj.svg';
import Check from '../../assets/icons/check.svg';
import Oczekuje from '../../assets/icons/oczekuje.svg';
import Zamknij from '../../assets/icons/zamknij.svg';

const Orders = ({ orders, handleDeleteOrder }) => {
    const { t } = useTranslation();

    const setBackgroundColor = (status) => {
        switch (status) {
            case 'completed':
                return "#c8f7a2";
            case 'rejected':
                return "#fdbbbb"
            default:
                return;
        }
    }

    const renderOrders = () => {
        if (!orders || orders.length === 0) return;

        return orders.map((o, i) => {
            const color = setBackgroundColor(o.status)
            return (
                <tr key={o._id}  style={{ backgroundColor: color }}>
                    <td><Link to={`/seller-order/${o._id}`}>{i + 1}. {_.get(o, 'orderNumber', o._id)}</Link></td>

                    <td className="text-center">
                        {_.get(o, 'customer.name')}
                    </td>

                    <td className="text-center">
                        <Moment format="DD/MM/YYYY">
                            {_.get(o, 'createdAt')}
                        </Moment>
                    </td>

                    {/* <td className="text-center"> */}
                        {renderOrderStatus(o.status)}
                    {/* </td> */}
                </tr>
            )
        })
    }

    const renderOrderStatus = (status) => {
        switch (status) {
            case 'new':
                return (
                    <td className="text-center">
                        <img src={Oczekuje} alt="" className="mr-4" />
                        <span>{t('newOrder')}</span>
                    </td>
                )
            case 'rejected':
                return (
                    <td className="text-center">
                        <img src={Zamknij} alt="" className="mr-4" />
                        <span>{t('rejected')}</span>
                    </td>
                )
            case 'completed':
                return (
                    <td className="text-center">
                        <img src={Check} alt="" className="mr-4" />
                        <span>{t('completed')}</span>
                    </td>
                )
            default:
                return null;
        }
    }


    return (
        <div className="col-12 mt-5">
            <div className="table-responsive">
                {orders && orders.length > 0 &&
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">{t('number')}</th>
                                <th scope="col">{t('client')}</th>
                                <th scope="col">{t('creationDate')}</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderOrders()}
                        </tbody>
                    </table>}
            </div>
        </div>
    )
}

export default Orders;