import React from 'react';

import Wyloguj from '../../../assets/icons/wyloguj.svg';

const HeaderLogout = ({ logout }) => {
    return (
        <div className="col-auto">
            <div className="logout cursor-pointer" onClick={() => logout()}>
                <img className="logout__icon" src={Wyloguj} alt="" />
            </div>
        </div>

    )
}

export default HeaderLogout;