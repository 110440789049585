import Api from '../../services/api';
import { isLoading, displayErrors } from '../../application/actions/application.actions';

const NAMESPACE = 'CLIENTS';

export const SET_CLIENT = `SET_CLIENT_${NAMESPACE}`;

export const getClient = (id) => dispatch => {
    dispatch(isLoading(true));

    Api.get(`salesman/user/${id}`).then(res => {
        if (res.success) {
            dispatch(setClient(res.document));
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

const setClient = (client) => dispatch => {
    dispatch({
        type: SET_CLIENT,
        client
    })
}