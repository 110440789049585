import Api from '../../services/api';
import { toast } from 'react-toastify';

import { isLoading, displayErrors } from '../../application/actions/application.actions';


const NAMESPACE = "ORDER";
export const SET_ORDER = `SET_ORDER_${NAMESPACE}`;
export const CLEAR_ORDER = `CLEAR_ORDER_${NAMESPACE}`;

export const getOrder = (id) => (dispatch) => {
    dispatch(isLoading(true));

    Api.get(`order/order/${id}`, {joinAmount: true}).then(res => {
        if (res.success) {
            dispatch(setOrder(res.document))
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

export const setOrder = (order) => dispatch => {
    dispatch({
        type: SET_ORDER,
        order
    })
}
export const clearOrder = () => dispatch => {
    dispatch({
        type: CLEAR_ORDER
    })
}

export const sendSplitOrder = (order, id) => (dispatch, getState) => {
    dispatch(isLoading(true));

    const history = getState().app.history;

    Api.put(`order/splitOrder/${id}`, { orders: order }).then(res => {
        if (res.success) {
            toast.info('Zamówienie zostało rozdzielone');
            history.replace('/seller-orders')
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}
export const acceptOrder = (id, orderDate) => (dispatch, getState) => {
    dispatch(isLoading(true));

    const history = getState().app.history;

    Api.put(`order/acceptOrder/${id}`, { orderDate: orderDate }).then(res => {
        if (res.success) {
            toast.info('Zamówienie zostało zaakceptowane');
            history.replace('/seller-orders');
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}
export const rejectOrder = (id) => (dispatch, getState) => {
    dispatch(isLoading(true));

    const history = getState().app.history;

    Api.put(`order/rejectOrder/${id}`).then(res => {
        if (res.success) {
            toast.info('Zamówienie zostało odrzucone');
            history.replace('/seller-orders');
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

