import React from 'react';
import { useTranslation } from 'react-i18next';

import TextInput from '../../../application/components/formComponents/TextInput';

const Stock = ({ minMin, minMax, setMinMax, midMin, setMidMin, midMax, setMidMax, maxMin, setMaxMin, maxMax, onClick }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-12">
                    <h1>{t('stock')}</h1>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12">


                    <div id="stockMinimum" className="stock__minimum">
                        <div className="row">
                            <div className="col-12">
                                <h4>{t('stockMinimal')}</h4>
                            </div>
                        </div>
                        <div className="row mt-4 align-items-center">
                            <div className="col-auto">
                                <div className="stock__status">
                                    <span className="stock__dot stock__dot--full"></span><span className="stock__dot stock__dot--empty ml-2"></span><span className="stock__dot stock__dot--empty ml-2"></span>
                                </div>
                            </div>
                            <div className="row mt-5 mt-sm-0">
                                <TextInput
                                    label="Min"
                                    type="number"
                                    // onChange={formik.setFieldValue}
                                    value={minMin}
                                    // errors={formik.errors.email}
                                    size="small"
                                    disabled={true}
                                />
                                <TextInput
                                    label="Max"
                                    type="number"
                                    onChange={setMinMax}
                                    value={minMax}
                                    // errors={formik.errors.email}
                                    size="small"
                                    min={1}
                                // max={midMin - 1}
                                />
                            </div>
                        </div>
                    </div>


                    <div id="stockMid" className="stock__minimum">
                        <div className="row">
                            <div className="col-12">
                                <h4>{t('stockOptimal')}</h4>
                            </div>
                        </div>
                        <div className="row mt-4 align-items-center">
                            <div className="col-auto">
                                <div className="stock__status">
                                    <span className="stock__dot stock__dot--full"></span><span className="stock__dot stock__dot--full ml-2"></span><span className="stock__dot stock__dot--empty ml-2"></span>
                                </div>
                            </div>
                            <div className="row mt-5 mt-sm-0">
                                <TextInput
                                    label="Min"
                                    type="number"
                                    onChange={setMidMin}
                                    value={midMin}
                                    // errors={formik.errors.email}
                                    size="small"
                                // min={minMax + 1}
                                // max={maxMin - 1}
                                />
                                <TextInput
                                    label="Max"
                                    type="number"
                                    onChange={setMidMax}
                                    value={midMax}
                                    // errors={formik.errors.email}
                                    size="small"
                                // min={minMax + 1}
                                // max={maxMin}
                                />
                            </div>
                        </div>
                    </div>


                    <div id="stockMax" className="stock__minimum">
                        <div className="row">
                            <div className="col-12">
                                <h4>{t('stockMaximal')}</h4>
                            </div>
                        </div>
                        <div className="row mt-4 align-items-center">
                            <div className="col-auto">
                                <div className="stock__status">
                                    <span className="stock__dot stock__dot--full"></span><span className="stock__dot stock__dot--full ml-2"></span><span className="stock__dot stock__dot--full ml-2"></span>
                                </div>
                            </div>
                            <div className="row mt-5 mt-sm-0">
                                <TextInput
                                    label="Min"
                                    type="number"
                                    onChange={setMaxMin}
                                    value={maxMin}
                                    // errors={formik.errors.email}
                                    // min={midMax + 1}
                                    // max={999}
                                    size="small"
                                />
                                <TextInput
                                    label="Max"
                                    type="text"
                                    // onChange={setMaxMax}
                                    value={maxMax}
                                    // errors={formik.errors.email}
                                    size="small"
                                    disabled={true}
                                // min={999}
                                // max={999}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="row mt-50">
                <div className="col-12 text-center">
                    <button id="stockSave" className="btn btn--primary"
                        onClick={() => onClick()}
                    >{t('saveAction')}</button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Stock;