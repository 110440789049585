import Api from '../../../services/api';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { isLoading, displayErrors } from '../../../application/actions/application.actions';

export const updateMailBox = (values) => (dispatch) => {
    dispatch(isLoading(true));

    Api.post(`application/mailConfig`, values).then(res => {
        if (res.success) {
            toast.info('Ustawienia zostały zaktualizowane')
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}
