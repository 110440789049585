import Api from '../../services/api';
import _ from 'lodash';
import { toast } from 'react-toastify';

import { isLoading, displayErrors, getApplicationSettings } from './application.actions';

export const NAMESPACE = "LOGIN";
export const SAVE_USER = `SAVE_USER_${NAMESPACE}`;
export const CLEAR_USER = `CLEAR_USER_${NAMESPACE}`;

export const login = (email, password) => dispatch => {
    dispatch(isLoading(true));
    Api.post('application/login', { email: email, password: password }).then(res => {
        if (res.success) {
            localStorage.setItem('token', res.token);
            dispatch(saveUser(res.user))
            dispatch(navigateToPageAfterLogin(_.get(res, 'user.type')));
            dispatch(getApplicationSettings());

        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    })
}

export const saveUser = (user) => dispatch => {
    dispatch({
        type: SAVE_USER,
        user
    })
}
export const clearUser = () => dispatch => {
    dispatch({
        type: CLEAR_USER
    })
    localStorage.removeItem('token');
}

export const navigateToPageAfterLogin = (userType) => (dispatch = null, getState) => {
    const history = getState().app.history;
    switch (userType) {
        case 'admin':
            history.push('/')
            break;
        case 'company':
            history.push('/')
            break;
        case 'salesman':
            history.push('/')
            break;
        // case 'admin':
        //     history.push('/admin')
        //     break;
        // case 'company':
        //     history.push('/products')
        //     break;
        // case 'salesman':
        //     history.push('/seller-clients')
        //     break;
        default:
            break;
    }
}

export const resetPassword = (email) => (dispatch = null, getState) => {
    const history = getState().app.history;
    dispatch(isLoading(true));

    Api.post('application/resetPassword', { email: email }).then(res => {
        if (res.success) {
            toast.info('Na twój email został wysłany link do zmiany hasła')
            history.push('/login')
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    })
}

export const changePassword = (password, passwordConfirm, token) => {
    return async (dispatch, getState) => {
        dispatch(isLoading(true));
        const history = getState().app.history;

        return Api.put(`application/resetPassword/${token}`, { password: password, passwordConfirm: passwordConfirm }).then(res => {
            dispatch(isLoading(false));
            if (res.success) {
                toast.info('Hasło zostało zmienione');
                history.push('/login')
                return Promise.resolve({ success: true })
            } else {
                if (res.errors) {
                    displayErrors(res.errors);
                    return Promise.resolve({ success: false, errors: res.errors })
                }
            }
        }).catch(err => {
            displayErrors(err);
            return Promise.resolve({ success: false, errors: err })
        })
    }
}