import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import TextInput from '../../../application/components/formComponents/TextInput';
import Switch from '../../../application/components/formComponents/Switch';
// import AutoComplete from '../../../application/components/formComponents/AutoComplete';
import AutoComplete2 from '../../../application/components/formComponents/AutoComplete2';
import Chips from '../../../application/components/formComponents/Chips';
import ConfirmationModal from '../../../application/components/modal/ConfirmationModal.component';


const AdminSeller = (props) => {
    const { history, clearSeller, deleteSellerAccount, editSellerAccount, createSellerAccount, clients, getClients, getSeller, getExternalSellers, externalSellers, seller,
        seller: { active, email, companies, name, foreignId, _id }
    } = props;
    const params = useParams();

    const { t } = useTranslation();

    const [clientsChips, setClientsChips] = useState([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);

    useEffect(() => {
        return () => {
            clearSeller();
        }
    }, [clearSeller])

    useEffect(() => {
        getExternalSellers();
        getClients();
    }, [getExternalSellers, getClients])

    useEffect(() => {
        if (params && params.id) {
            getSeller(params.id);
        }
    }, [params.id])

    useEffect(() => {
        if (!seller || !seller.companies) return;
        setClientsChips(seller.companies);
    }, [seller])

    const handleClientAdd = (client) => {
        if (client) {
            //prevent duplicates
            if (
                _.find(clientsChips, (o) => {
                    return o._id === client._id
                })
            ) {
                //do nothing
            } else {
                setClientsChips(prev => [...prev, client]);
            }
        }
    }

    const renderClientsChips = () => {
        return clientsChips.map(chip => {
            return <Chips
                item={chip}
                onClick={handleDeleteClientChip}
            />
        })
    }

    const renderSellerChip = (seller, setFieldValue) => {
        if (!seller) return;

        return <Chips
            key={seller.name}
            item={seller}
            onClick={() => setFieldValue('seller', "")}
        />

    }

    const handleDeleteClientChip = (client) => {
        setClientsChips(clientsChips.filter((e) => (e._id !== client._id)))
    }

    const handleDeleteSeller = (id) => {
        setIdToDelete(id);
        setShowConfirmationModal(true);
    }

    const handleCancel = () => {
        clearSeller();
        history.replace('/admin/sellers');
        window.location.reload();
    }

    const handleConfirmDeleteModal = () => {
        deleteSellerAccount(idToDelete);
        setShowConfirmationModal(false);
        history.replace('/admin/sellers');
        window.location.reload();
    }

    const handleCancelDeleteModal = () => {
        setIdToDelete(null);
        setShowConfirmationModal(false);
    }

    return (
        <div className="col-12">
            {
                showConfirmationModal &&
                <ConfirmationModal
                    text={'Usunąć handlowca?'}
                    onConfirm={handleConfirmDeleteModal}
                    onCancel={handleCancelDeleteModal}
                />
            }
            <div className="col">
                <div className="row">
                    <div className="col-12">
                        <div className="rounded-box p-5">
                            <div className="row">
                                <div className="col-12">
                                    <h1> <Trans i18nKey="addUserSeller"></Trans></h1>
                                </div>

                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        email: (email) ? email : '',
                                        password: '',
                                        active: (typeof active !== 'undefined') ? active : true,
                                        seller: (seller && !_.isEmpty(seller)) ? { name: name, _id: foreignId } : '',
                                        clients: (companies) ? companies : []
                                    }}
                                    validationSchema={Yup.object({
                                        email: Yup.string()
                                            .required(`${t('loginRequired')}`)
                                            .email(`${t('invalidEmail')}`)
                                        ,
                                        password: (_.isEmpty(seller)) ? Yup.string().required(`${t('passwordError')}`)
                                            : ''
                                        ,
                                        seller:
                                            Yup.object()
                                                .required(`${t('sellerRequired')}`)
                                    })}
                                    validateOnChange={false}
                                    validateOnBlur={false}
                                    onSubmit={(values, { resetForm, setErrors, setFieldValue }) => {
                                        if (clientsChips) {
                                            values.clients = clientsChips;
                                        }

                                        if (seller && !_.isEmpty(seller)) {
                                            editSellerAccount(_id, values).then(res => {
                                                if (res.success) {
                                                    resetForm();
                                                    clearSeller();
                                                    history.replace('/admin/sellers');
                                                    setClientsChips([])
                                                    window.location.reload();
                                                } else {
                                                    if (res.errors) {
                                                        setErrors(res.errors);
                                                    }
                                                }
                                            });
                                        } else {
                                            createSellerAccount(values).then(res => {
                                                if (res.success) {
                                                    resetForm();
                                                    setClientsChips([]);
                                                    window.location.reload();
                                                } else {
                                                    if (res.errors) {
                                                        setErrors(res.errors);
                                                    }
                                                }
                                            });
                                        }
                                    }}
                                >
                                    {formik => (
                                        <React.Fragment>
                                            <TextInput
                                                id="email"
                                                label="E-mail"
                                                type="text"
                                                onChange={formik.setFieldValue}
                                                value={formik.values.email}
                                                errors={formik.errors.email}
                                                size={'medium'}
                                            />
                                            <TextInput
                                                id="password"
                                                label={t('password')}
                                                type="text"
                                                onChange={formik.setFieldValue}
                                                value={formik.values.password}
                                                errors={formik.errors.password}
                                                size={'medium'}
                                            />

                                            <div className="col-md-12 mt-4">
                                                <AutoComplete2
                                                    label={t('seller')}
                                                    options={(externalSellers) ? externalSellers : null}
                                                    val={_.get(formik, 'values.seller.name')}
                                                    onChange={(o) => formik.setFieldValue('seller', o)}
                                                    getFunction={getExternalSellers}
                                                    errors={formik.errors.seller}
                                                />
                                            </div>

                                            {renderSellerChip(_.get(formik, 'values.seller'), formik.setFieldValue)}

                                            <div className="col-md-12 mt-4">
                                                <AutoComplete2
                                                    label={t('clients')}
                                                    options={(clients) ? clients : null}
                                                    onChange={(client) => handleClientAdd(client)}
                                                    getFunction={getClients}
                                                    errors={formik.errors.clients}
                                                />
                                            </div>

                                            {renderClientsChips()}

                                            <Switch
                                                id="active"
                                                label={t('active')}
                                                onChange={formik.setFieldValue}
                                                value={_.get(formik, 'values.active')}
                                            />

                                            {params.id ?
                                                <React.Fragment>
                                                    <div className="col-lg-auto text-center text-lg-left mt-5">
                                                        <button id="adminClientsCancel" className="btn btn--secondary"
                                                            onClick={handleCancel}
                                                        >{t('cancelAction')}</button>
                                                    </div>
                                                    <div className="col-lg-auto text-center text-lg-left mt-5">
                                                        <button id="adminClientsDelete" className="btn btn-danger"
                                                            onClick={() => handleDeleteSeller(params.id)}
                                                        >{t('deleteAction')}</button>
                                                    </div>
                                                    <div className="col-lg-auto text-center text-lg-left mt-5">
                                                        <button onClick={formik.handleSubmit} id="adminClientsSave" className="btn btn--primary">{t('saveAction')}</button>
                                                    </div>
                                                </React.Fragment>
                                                :

                                                <div className="col-12 text-center mt-5">
                                                    <button onClick={formik.handleSubmit} id="adminClientsSave" className="btn btn--primary">{t('saveAction')}</button>
                                                </div>
                                            }

                                        </React.Fragment>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminSeller;