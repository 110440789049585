import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    Link
} from "react-router-dom";

import TextInput from '../formComponents/TextInput';

const LostPassword = (props) => {
    const { resetPassword } = props;
    const { t } = useTranslation();

    return (
        <section id="login" className="login content mt-50">
            <div className="container">
                <div className="d-flex row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="reset__form">
                            <div className="row">
                                <div className="col-12">
                                    <h2 className="h1 text-center">{t('resetPassword')}</h2>
                                </div>
                                <Formik
                                    initialValues={{ email: "" }}
                                    validationSchema={Yup.object({
                                        email: Yup.string()
                                            .required(`${t('emailRequired')}`)
                                            .email(`${t('invalidEmail')}`)
                                    })}
                                    validateOnChange={false}
                                    validateOnBlur={false}
                                    onSubmit={(values) => {
                                        resetPassword(values.email)
                                    }}
                                >
                                    {formik => (
                                        <form className="row" onSubmit={formik.handleSubmit}>
                                            <div className="col-12 mt-5">
                                                <p className="text-center">{t('provideEmail')}</p>
                                            </div>
                                            <TextInput
                                                id="email"
                                                label="E-mail"
                                                type="text"
                                                onChange={formik.setFieldValue}
                                                value={formik.values.email}
                                                errors={formik.errors.email}
                                            />
                                            <div className="col-12 mt-5 text-center">
                                                <Link className="btn btn--secondary reset__back" to="/login">{t('backToSignInAction')}</Link>
                                                <button className="btn btn--primary mt-4 mt-md-0 ml-md-4">{t('resetPasswordAction')}</button>
                                            </div>
                                        </form>
                                    )}
                                </Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LostPassword;