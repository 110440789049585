import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import './pagination.css';

const Pagination = (props) => {
    const { onNextPageClick, onPrevPageClick, onPageClick, params: { offset, limit }, total } = props;

    const [pages, setPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        setPages(Math.ceil(total / limit));
    }, [total, limit])
    useEffect(() => {
        setCurrentPage((offset / limit) + 1);
    }, [offset, limit])


    const calculatePageValues = () => {
        return [
            1,
            (pages && (currentPage) - 1 > 1) ? ' ...' : null,
            (pages && (currentPage) - 1 > 1) ? (currentPage) - 1 : null,
            (currentPage),
            (pages && currentPage < pages) ? (currentPage) + 1 : null,
            (pages && currentPage < pages - 1) ? '... ' : null,
            (pages) ? pages : null
        ]
    }

    const renderPageNumbers = () => {
        let pageValues = calculatePageValues();
        if (pageValues) {
            pageValues = _.union(pageValues);
            pageValues = pageValues.filter(Boolean);
            
            if (pageValues.length <= 1) return;

            return pageValues.map((v, i) => {
                if (typeof (v) === 'number') {
                    return <span key={i} className={`pagination__item mx-2 ${(currentPage === v) ? 'active' : null}`} onClick={() => onPageClick(v)}>{v}</span>
                } else {
                    return <span key={i} >{v}</span>
                }
            })
        }
    }

    return (
        <div id="pagination" className="pagination d-flex align-items-center justify-content-center px-4">
            {
                (offset > 0) ? <span className="pagination__prev mx-2" onClick={() => onPrevPageClick()}>{'<'}</span> : null
            }

            {renderPageNumbers()}

            {
                (offset + limit >= total) ? null : <span className="pagination__next mx-2" onClick={() => onNextPageClick()}>{'>'}</span>
            }

        </div>
    )
}

export default Pagination;